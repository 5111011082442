import React from "react"
import {
  Typography,
  CardContent,
  ButtonGroup,
  Button,
} from "@material-ui/core";

const MobilePlatformContent = (props) => {
    let { platform, mobileInnerContent } = props
    return (
        <CardContent>
          <CardContent>
          {mobileInnerContent()}
          </CardContent>
          <CardContent>
            <ButtonGroup
              fullWidth
              aria-label="full width outlined button group"
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  window.location.href =
                    platform.url
                }}
              >
                Download Blix For {platform.name}
              </Button>
            </ButtonGroup>
          </CardContent>
          <CardContent>
            <Typography gutterBottom align="center" style={{ color: "#000" }}>
              NOT ON {platform.name.toUpperCase()}?
            </Typography>
            <Typography
              gutterBottom
              align="center"
              style={{ color: "#000", fontSize: "13px" }}
            >
              Blix is available on{" "}
              {platform.otherPlatforms.map((comp, i) => {
                  return (
                  <span style={{color: "inherit"}}><a key={i}
                        href={platform.otherPlatformsUrl[comp]}
                        style={{
                        textDecoration: "none",
                        color: "#146CE7",
                        fontWeight: "bold",
                        }}
                    >
                        {comp}
                    </a>
                    {(i < platform.otherPlatforms.length -1) ? ' and ' : ''}
                    </span>
                    )
                  
              })}
              
            </Typography>
          </CardContent>
        </CardContent>
    )

}

export default MobilePlatformContent