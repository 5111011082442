import React from 'react'
import SEO from '../components/seo';
import Layout from '../components/layout';
import DownloadForm from '../components/download-page/download-form'
import { graphql, StaticQuery } from 'gatsby';

const Download = () => {
    return (
      
        <StaticQuery
        query={graphql`
        query DownloadPageQuery {
          site {
            siteMetadata {
              title
              env
              imagesPrefix
            }
          }
        }
        `}
        render={data => (
          <Layout location="">
            <div className="download-wrapper">
              <SEO title='Download' description='download Blix to your pc, mobile'/>
              <DownloadForm imagesPrefix={data.site.siteMetadata.imagesPrefix} />
            </div>
        </Layout>
        )}
        />
    );
}
export default Download;