import React from "react"
import {
  Grid,
  Typography,
  CardHeader,
  CardContent,
  Card,
  Container,
} from "@material-ui/core"
// import Image from '../graphql-image'
// import DownloadSection from '../downloads-section'
import { osName, isMobile, getUA } from "react-device-detect"
import MobilePlatformContent from "./mobile-platform-content"
import DesktopPlatformContent from "./desktop-platform-content"

class DownloadForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      platform: {
        android: {
          name: "Android",
          otherPlatforms: ["iPhone"],
          url: "https://play.google.com/store/apps/details?id=com.onblix.app",
          otherPlatformsUrl: {
            iPhone:
              "https://apps.apple.com/us/app/blix-for-teams/id1481511233?ls=1",
          },
        },
        ios: {
          name: "iOS",
          otherPlatforms: ["Android"],
          url: "https://apps.apple.com/us/app/blix-for-teams/id1481511233?ls=1",
          otherPlatformsUrl: {
            Android:
              "https://play.google.com/store/apps/details?id=com.onblix.app",
          },
        },
        mac: {
          name: "Mac",
          url:
            "https://apps.apple.com/us/app/blix-for-teams/id1497035715?mt=12",
          image: `${this.props.imagesPrefix}/images/download-page/Desktop-mac.png`,
          otherPlatforms: ["Windows", "Linux"],
          otherPlatformsUrl: {
            Windows: "",
            Linux: "https://snapcraft.io/blix",
          },
        },
        windows: {
          name: "Windows",
          url: "",
          image: `${this.props.imagesPrefix}/images/download-page/Desktop-windows.png`,
          otherPlatforms: ["Mac", "Linux"],
          otherPlatformsUrl: {
            Mac:
              "https://apps.apple.com/us/app/blix-for-teams/id1497035715?mt=12",
            Linux: "https://snapcraft.io/blix",
          },
        },
        linux: {
          name: "Linux",
          url: "https://snapcraft.io/blix",
          image: `${this.props.imagesPrefix}/images/download-page/Desktop-linux.png`,
          otherPlatforms: ["Windows", "Mac"],
          otherPlatformsUrl: {
            Mac:
              "https://apps.apple.com/us/app/blix-for-teams/id1497035715?mt=12",
            Windows: "",
          },
        },
      },
    }
  }

  componentDidMount() {
    let platform = { ...this.state.platform }
    if (window.location.search.includes("nrm")) {
      platform.windows.url =
        "https://www.microsoft.com/en-us/p/blix-for-teams/9n7dl4qpq67x?cid=nrm"
      platform.mac.otherPlatformsUrl.Windows =
        "https://www.microsoft.com/en-us/p/blix-for-teams/9n7dl4qpq67x?cid=nrm"
      platform.linux.otherPlatformsUrl.Windows =
        "https://www.microsoft.com/en-us/p/blix-for-teams/9n7dl4qpq67x?cid=nrm"
      this.setState({ platform })
    } else {
      platform.windows.url =
        "https://www.microsoft.com/en-us/p/blix-for-teams/9n7dl4qpq67x?cid=dl"
      platform.mac.otherPlatformsUrl.Windows =
        "https://www.microsoft.com/en-us/p/blix-for-teams/9n7dl4qpq67x?cid=dl"
      platform.linux.otherPlatformsUrl.Windows =
        "https://www.microsoft.com/en-us/p/blix-for-teams/9n7dl4qpq67x?cid=dl"
      this.setState({ platform })
    }
  }

  mobileInnerContent = () => {
    let { imagesPrefix } = this.props;
    return (
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
        spacing={1}
      >
        <Grid item xs={4} style={{ padding: "0" }}>
          <a href="https://play.google.com/store/apps/details?id=com.onblix.app">
            <img
              src={`${imagesPrefix}/images/download-page/Android-device.png`}
              alt="blix mail mobile"
            />
          </a>
          <Typography gutterBottom align="center">
            <a
              href="https://play.google.com/store/apps/details?id=com.onblix.app"
              style={{
                fontWeight: "bold",
                color: "#000",
                textDecoration: "none",
              }}
            >
              Android
            </a>
          </Typography>
        </Grid>
        <Grid item xs={4} style={{ padding: "0" }}>
          <a href="https://apps.apple.com/us/app/blix-for-teams/id1481511233?ls=1">
            <img
              src={`${imagesPrefix}/images/download-page/iOS-device.png`}
              alt="blix iphone chat"
            />
          </a>
          <Typography gutterBottom align="center">
            <a
              href="https://apps.apple.com/us/app/blix-for-teams/id1481511233?ls=1"
              style={{
                fontWeight: "bold",
                color: "#000",
                textDecoration: "none",
              }}
            >
              iPhone
            </a>
          </Typography>
        </Grid>
      </Grid>
    )
  }

  renderMobileContent = () => {
    let { platform } = this.state
    if (!isMobile) {
      return (
        <CardContent>
          <CardContent>{this.mobileInnerContent()}</CardContent>
          <CardContent>
            <Typography gutterBottom align="center" style={{ color: "#000" }}>
              Visit blix.net/download from your
              <br /> mobile device to get started
            </Typography>
          </CardContent>
        </CardContent>
      )
    } else if (osName === "Android") {
      return (
        <MobilePlatformContent
          platform={platform.android}
          mobileInnerContent={this.mobileInnerContent}
        />
      )
    } else if (osName === "iOS") {
      return (
        <MobilePlatformContent
          platform={platform.ios}
          mobileInnerContent={this.mobileInnerContent}
        />
      )
    }
  }

  renderDesktopContent = () => {
    let { platform } = this.state;
    let { imagesPrefix } = this.props;
    if (osName === "Mac OS") {
      return <DesktopPlatformContent platform={platform.mac} />
    } else if (osName === "Windows") {
      return <DesktopPlatformContent platform={platform.windows} />
    } else if (isMobile) {
      return (
        <CardContent>
          <CardContent>
            <img
              src={`${imagesPrefix}/images/download-page/Desktop-windows.png`}
              alt="blix download windows"
            />
          </CardContent>
          <CardContent>
            <Typography gutterBottom align="center" style={{ color: "#000" }}>
              Visit blix.net/download from your desktop device to get started
            </Typography>
          </CardContent>
        </CardContent>
      )
    } else if (getUA.includes("Linux")) {
      return <DesktopPlatformContent platform={platform.linux} />
    }
  }

  render() {
    return (
      <Container style={{ overflow: "hidden" }}>
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          spacing={3}
          style={{ paddingTop: "120px", paddingBottom: "30px" }}
        >
          <Grid item>
            <Card className="desktop-card">
              <CardHeader
                className="header-card"
                align="center"
                title="Download Blix on your "
              ></CardHeader>
              <Typography
                variant="h5"
                align="center"
                style={{ color: "#000", fontWeight: "bold" }}
              >
                Desktop
              </Typography>
              {this.renderDesktopContent()}
            </Card>
          </Grid>
          <Grid item>
            <Card className="mobile-card">
              <CardHeader
                className="header-card"
                align="center"
                title="Download Blix on your"
              />
              <Typography
                variant="h5"
                align="center"
                color="textPrimary"
                style={{ fontWeight: "bold" }}
              >
                Mobile
              </Typography>
              {this.renderMobileContent()}
            </Card>
          </Grid>
        </Grid>
      </Container>
    )
  }
}
export default DownloadForm