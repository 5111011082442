import React from "react"
import { Typography, CardContent, ButtonGroup, Button } from "@material-ui/core"

const DesktopPlatformContent = props => {
  let { platform } = props
  return (
    <CardContent>
      <CardContent>
        <a href={platform.url}>
          <img
            src={platform.image}
            alt="blix download windows"
          />
        </a>
      </CardContent>
      <CardContent>
        <ButtonGroup fullWidth aria-label="full width outlined button group">
          <Button
            variant="contained"
            style={{ background: "#4571C0" }}
            onClick={() => {
              window.location.href = platform.url
            }}
          >
            Download Blix For {platform.name}
          </Button>
        </ButtonGroup>
      </CardContent>
      <CardContent>
        <Typography gutterBottom align="center" style={{ color: "#000" }}>
          NOT ON {platform.name.toUpperCase()}?
        </Typography>
        <Typography
          gutterBottom
          align="center"
          style={{ color: "#000", marginTop: "28.5px" }}
        >
          Blix is available on{" "}
          {platform.otherPlatforms.map((comp, i) => {
            return (
              <span key={i} style={{color: 'inherit'}}><a
                href={platform.otherPlatformsUrl[comp]}
                style={{
                  textDecoration: "none",
                  color: "#000",
                  fontWeight: "bold",
                }}
              >
                {comp}
              </a>
              {(i < platform.otherPlatforms.length -1) ? ' and ' : ''}
              </span>
            )
          })}
        </Typography>
      </CardContent>
    </CardContent>
  )
}

export default DesktopPlatformContent
